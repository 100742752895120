import { Button, Input } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormController from "../../components/FormController";
import { newCustomerForm } from "../../forms/customer/newCustomer";
import dayjs from "dayjs";
import { Trash } from "iconoir-react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { MaskedInput } from "antd-mask-input";
import { ErrorMessage } from "@hookform/error-message";
import toast from "react-hot-toast";
import validator from "validator";
import { formatCurrency, formatPhoneNumber } from "../../data/utils";
import { states } from "../../data/states";
import { CreateACustomer } from "../../actions/customer";
import { newVendorForm } from "../../forms/vendor/newVendor";

const NewVendorView = () => {
  const [step, setStep] = useState(0);
  const [vendor, setVendor] = useState({
    name: "",
    code: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
    contact: {
      name: "",
      phoneNumber: "",
      email: "",
      fax: "",
    },
    status: "Active",
    terms: "NET 30",
  });

  const navigate = useNavigate();

  const submitVendor = (data) => {
    setVendor(data);
    setStep(1);
  };

  const steps = [
    { id: "01", name: "Vendor Details", description: "Create a Vendor Profile", status: step > 0 ? "complete" : "current" },
    {
      id: "02",
      name: "Address & Contact",
      description: "Create a Vendor Profile",
      href: "#",
      status: step < 1 ? "upcoming" : step > 1 ? "complete" : "current",
    },
    { id: "03", name: "Preview", description: "Create a Vendor Profile", href: "#", status: step < 2 ? "upcoming" : "current" },
  ];

  const renderStep = () => {
    if (step === 0) {
      return <FormController onSubmit={submitVendor} fields={newVendorForm} values={vendor} buttonText={"Next"} fullWidth={true} />;
    } else if (step === 1) {
      //
    } else if (step === 2) {
      return (
        <div className="flex flex-col items-center justify-start w-full h-full gap-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-6 font-sans bg-white border rounded-md shadow-md border-gray-200/60 shadow-gray-200">
            <div className="flex flex-row items-center justify-between w-full">
              <p className="text-xl font-bold text-black">General Information</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Vendor Name</p>
              <p>{vendor.name}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Vendor Code</p>
              <p>{vendor.code}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Vendor Status</p>
              <p>{vendor.status}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Payment Terms</p>
              <p>{vendor.terms}</p>
            </div>
          </div>
          <div className="flex items-center justify-between w-full gap-4">
            <div className="flex flex-col items-start justify-start w-1/2 gap-2 p-6 bg-white border rounded-md shadow-md shadow-gray-200 border-gray-200/60">
              <div className="flex flex-row items-center justify-between w-full">
                <p className="text-xl font-bold text-black">Contact</p>
              </div>
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Name</p>
                  <p>{vendor.contact.name ?? "Not Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Phone Number</p>
                  <p>{formatPhoneNumber(vendor.contact.phoneNumber)}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Email</p>
                  <p>{vendor.contact.email ?? "Not Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Fax</p>
                  <p>{formatPhoneNumber(vendor.contact.fax)}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-1/2 gap-2 p-6 bg-white border rounded-md shadow-md shadow-gray-200 border-gray-200/60">
              <div className="flex flex-row items-center justify-between w-full">
                <p className="text-xl font-bold text-black">Address</p>
              </div>
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Address</p>
                  <p>
                    {vendor.address.address1 ? vendor.address.address1 : "Not Provided"}
                    {vendor.address.address2 && vendor.address.address2.length > 0 ? `, ${vendor.address.address2}` : ""}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">City</p>
                  <p>{vendor.address.city ? vendor.address.city : "Not Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">State</p>
                  <p>{vendor.address.state ? states.find((s) => s.value === vendor.address.state)?.label : "Not Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">ZIP Code</p>
                  <p>{vendor.address.zip ? vendor.address.zip : "Not Provided"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end w-full px-6 py-4 my-4 border-t border-gray-300">
            <Button onClick={() => addCustomer()} type="primary">
              Add Customer
            </Button>
          </div>
        </div>
      );
    }
  };

  const processVendorData = (vendor) => {
    const contacts = [];
    const addresses = [];

    if (vendor.contact && Object.values(vendor.contact).some((value) => value)) {
      contacts.push({ ...vendor.contact, primary: true });
    }

    if (vendor.address && Object.values(vendor.address).some((value) => value)) {
      addresses.push({ ...vendor.address, primary: true });
    }

    return { contacts, addresses };
  };

  const addCustomer = () => {
    let payload = {
      name: vendor.name,
      code: vendor.code,
      status: vendor.status,
      terms: vendor.terms,
      notes: [],
    };

    const { contacts, addresses } = processVendorData(vendor);

    payload.contact = contacts;
    payload.address = addresses;

    console.log(payload);
    // CreateACustomer(payload)
    //   .then((res) => {
    //     toast.success("Customer added successfully");
    //     navigate(`/customers/${res.data.customerId}`);
    //   })
    //   .catch((err) => {
    //     toast.error(err.response.data ? err.response.data.response : "An error occurred while adding the customer");
    //   });
  };

  return (
    <div className="flex flex-col items-start justify-between w-full h-full gap-4">
      <div className="flex items-center justify-between w-full pb-4 border-b border-gray-200">
        <h1 className="text-lg font-bold">Add a New Vendor Profile</h1>
        <nav className="flex items-center justify-center" aria-label="Progress">
          <p className="text-sm font-medium">
            Step {steps.findIndex((step) => step.status === "current") + 1} of {steps.length}
          </p>
          <ol role="list" className="flex items-center ml-8 space-x-5">
            {steps.map((step) => (
              <li key={step.name}>
                {step.status === "complete" ? (
                  <p className="block h-2.5 w-2.5 rounded-full bg-wbs-red" />
                ) : step.status === "current" ? (
                  <p className="relative flex items-center justify-center" aria-current="step">
                    <span className="absolute flex w-5 h-5 p-px" aria-hidden="true">
                      <span className="w-full h-full rounded-full bg-wbs-red/25" />
                    </span>
                    <span className="relative block h-2.5 w-2.5 rounded-full bg-wbs-red" aria-hidden="true" />
                  </p>
                ) : (
                  <p className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400" />
                )}
              </li>
            ))}
          </ol>
        </nav>
        <Button onClick={() => navigate(-1)}>Cancel & Go Back</Button>
      </div>
      <div className="flex flex-col items-start justify-start w-full h-full overflow-y-auto">{renderStep()}</div>
    </div>
  );
};

export default NewVendorView;
