import * as React from "react";
import { SidebarProvider, SidebarTrigger } from "./Sidebar";
import { AppSidebar } from "./AppSidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { Separator } from "./sidebar/Separator";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "./sidebar/Breadcrumb";
import { Popover } from "antd";
import { TerminalTag } from "iconoir-react";
import { LogOut, LogOutIcon, TerminalSquare } from "lucide-react";

// const Layout = ({ children }: { children: React.ReactNode }) => {
export const Layout = ({ authState }) => {
  const navigate = useNavigate();

  const logout = () => {
    authState.authDispatch({ type: "logged-out" });
    navigate("/");
  };
  return (
    <SidebarProvider>
      <AppSidebar />
      <main className="w-full">
        <header className="flex items-center w-full h-16 gap-2 px-4 border-b shrink-0">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="h-4 mr-1 bg-black" />
          <Breadcrumb>
            <BreadcrumbList>
              {/* <BreadcrumbItem className="hidden md:block">
                <BreadcrumbLink href="#">Building Your Application</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="hidden md:block" /> */}
              <BreadcrumbItem>
                <BreadcrumbPage>Wrightsboro Supply ERP</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <div className="flex items-center justify-end gap-2 ml-auto">
            <Popover content="Point of Sale Terminal" placement="bottomLeft">
              <div
                className="flex items-center justify-center duration-300 ease-in-out rounded-full group hover:bg-slate-100"
                onClick={() => navigate("/sale")}
              >
                <TerminalSquare strokeWidth={1.2} size={21} className="text-black group-hover:cursor-pointer" />
              </div>
            </Popover>
            <Popover content="Logout" placement="bottomLeft">
              <div className="flex items-center justify-center duration-300 ease-in-out rounded-full group hover:bg-slate-100" onClick={() => logout()}>
                <LogOutIcon strokeWidth={1.2} size={20} className="text-black group-hover:cursor-pointer" />
              </div>
            </Popover>
          </div>
        </header>
        <section className="p-4">
          <Outlet />
        </section>
      </main>
    </SidebarProvider>
  );
};
