import { useEffect, useState } from "react";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const formatCurrency = (val) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(val);
};

export const priceTiers = (tier) => {
  let vals = {
    tier1: "Tier 1",
    tier2: "Tier 2",
    tier3: "Tier 3",
    tier4: "Tier 4",
    tier5: "Tier 5",
    tier6: "Tier 6",
    tier7: "Tier 7",
    tier8: "Tier 8",
    tier9: "Tier 9",
    tier10: "Tier 10",
  };
  return vals[tier];
};

export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString || typeof phoneNumberString !== "string") return "Not Provided";
  const match = phoneNumberString && phoneNumberString.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  if (match) {
    return !match[2] ? match[1] : `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ""}`;
  }
  return "Not Provided";
};

export const multiply = (a, b) => {
  let numberOne = parseFloat(a.toFixed(2));
  let numberTwo = parseFloat(b.toFixed(2));
  let result = numberOne * numberTwo;
  result = parseFloat(result.toFixed(2));
  return result;
};

export const add = (a, b) => {
  let numberOne = parseFloat(a.toFixed(2));
  let numberTwo = parseFloat(b.toFixed(2));
  let result = numberOne + numberTwo;
  result = parseFloat(result.toFixed(2));
  return result;
};

const MOBILE_BREAKPOINT = 768;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(undefined);

  useEffect(() => {
    const mql = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT - 1}px)`);
    const onChange = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    };
    mql.addEventListener("change", onChange);
    setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    return () => mql.removeEventListener("change", onChange);
  }, []);

  return !!isMobile;
};

export const cn = (...inputs) => {
  return twMerge(clsx(inputs));
};
